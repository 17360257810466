import { Button, Table as AntTable, Tag, Tooltip, Empty } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Api from "../../../utils/Axios";

export default function Table({ filters , setDataRecord }) {

  const [data, setData] = useState([]);
  const [recordFilters, setRecordFilters] = useState(undefined)
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState(undefined);
  const { activeOrganization } = useSelector((state) => state.auth)

  const getReport = useCallback(async () => {
    try {
      setLoading(true);
      const { data: { data: records, pagination: paginationData } } = await Api.Get(`organization/${activeOrganization.id}/report/top-performer`, recordFilters)
      setData(records);
      setPagination(paginationData)
    } catch (error) {
      console.error(error);
    }
    finally{
      setLoading(false);
    }
  }, [activeOrganization, recordFilters])

  const handleTableChange = useCallback((pagination, filters, sorter, extra) => {
    const { field, order } = sorter;
    const orderByMap = {
      ascend: 'asc',
      descend: 'desc'
    };

    const sortByMap = {
      active_time: 'active_hours',
      logged_time: 'logged_hours'
    }

    setRecordFilters((prev) => ({
      ...prev,
      sort_by: sortByMap[field],
      order_by: orderByMap[order]
    }));
  }, []);


  const handleLoadMore = useCallback(async () => {
    const { data: { data: records, pagination: paginationData } } = await Api.Get(pagination?.links?.next, recordFilters)
    setData((prev) => [
      ...prev,
      ...records
    ]);
    setPagination(paginationData)
  }, [pagination, recordFilters])

  useEffect(() => {
    getReport()
  }, [getReport])

  useEffect(() => {
    if (!filters) return

    setRecordFilters(filters);
  }, [filters])

    useEffect(() => {
     setDataRecord(data)
    }, [data])

  const columns = [
    {
      title: 'Rank',
      dataIndex: "rank",
      align: "center",
      render: (_, __, index) => <p>{`#${index + 1}`}</p>,
    },
    {
      title: "Name",
      dataIndex: "user",
      align: "center",
      render: (_, record) => (
        <div className="flex items-center gap-2 w-full mx-auto">
          <div className="border-4 rounded-full border-primary overflow-hidden">
            <img
              src={record.avatar}
              className="w-12 h-12 object-cover border-4 border-primary"
              alt="profile"
            />
          </div>
          <Tooltip title={record.name} overlayStyle={{ maxWidth: 300 }}>
  <div className="max-w-[150px] truncate cursor-pointer">
    <p className="font-semibold text-left whitespace-nowrap overflow-hidden text-ellipsis">
      {record.name}
    </p>
  </div>
</Tooltip>
        </div>
      ),
      
    },
    {
      title: "Active Time",
      dataIndex: "active_time",
      align: "center",
      sorter: () => { }
    },
    {
      title: "Logged Hours",
      dataIndex: "logged_time",
      align: "center",
      sorter: () => { }
    },
    {
      title: "Department",
      dataIndex: "department_name",
      align: "center",
      render: (departments) => {
        const firstTwoDepartments = departments.slice(0, 2);
        const restDepartments = departments.slice(2);
        return (
          <>
            {firstTwoDepartments.map((department, index) => (
              department ? 
              <Tag color='purple' className='font-poppins mt-1' key={index}>
                {department}
              </Tag>
:
<Tag color='red' className='font-poppins mt-1' key={index}>
{"No Department Found"}
</Tag>
            ))}
            
            {restDepartments.length > 0 && (
              <Tooltip title={restDepartments.map(department => department).join(', ')}>
                <Tag color='purple' className='font-poppins mt-1'>
                  +{restDepartments.length}
                </Tag>
              </Tooltip>
            )}
          </>
        )

      }
    },
  ];

  return (
    <div className='overflow-auto bg-white rounded-md p-2 mt-3'>
      <AntTable
        dataSource={data}
        columns={columns}
        onChange={handleTableChange}
        className='m-2'
        pagination={false}
        loading={loading}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Top Performer Found"
            />
          ),
        }}
      />
      {
        pagination?.links?.next && (
          <div className="load-more sticky flex justify-center top-0">
            <Button
              onClick={handleLoadMore}
              type="primary"
              className="font-poppins py-2 px-5 h-auto"
            >Load More</Button>
          </div>
        )
      }
    </div>
  );
}
