import React, { useCallback, useEffect, useState } from "react";
import MainLayout from "../../layouts/MainLayout";
import ArrowDown from "../../assets/Arrowdown.svg";
import { Button, Layout, Select, Empty } from "antd";
import ScreenshotSide from "../../components/Screenshots/ScreenshotSide";
import MemberSelection from "../../components/Screenshots/MemberSelection";
import { Content } from "antd/es/layout/layout";
import CustomDatePicker from "../../components/CustomDatePicker";
import { useSelector } from "react-redux";
import Api from "../../utils/Axios";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import LazyLoad from "../../components/LazyLoad";
import ScreenshotsLoader from "../../components/Skeletons/ScreenshotsLoader";
import "./screenshot.css"

export default function Screenshots() {
  const { activeOrganization } = useSelector((state) => state.auth);
  const [screenshots, setScreenshots] = useState([]);
  const [screenshotsLoading, setScreenshotsLoading] = useState(false);
  const [members, setMembers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [sortBy, setSortBy] = useState("desc");
  const [statuses, setStatuses] = useState([]);
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    date: dayjs().format("YYYY-MM-DD"),
    sort_by: sortBy,
    projects: [],
    users: [],
  });

  const handleStatusChange = useCallback((value) => {
    setFilters((prev) => ({
      ...prev,
      statuses: value,
    }));
  }, []);

  const handleProjectChange = useCallback((value) => {
    setFilters((prev) => ({
      ...prev,
      projects: value,
    }));
  }, []);

  const handleMembersChange = useCallback((value) => {
    setFilters((prev) => ({
      ...prev,
      users: value,
    }));
  }, []);

  const handleDateChange = useCallback((value) => {
    setFilters((prev) => ({
      ...prev,
      date: value.format("YYYY-MM-DD"),
    }));
  }, []);

  const getScreenshots = useCallback(async () => {
    try {
      setScreenshotsLoading(true);
      const { data } = await Api.Post(
        `/organization/${activeOrganization?.id}/screenshot`,
        { filters }
      );
      setScreenshots(data);
    } catch (error) {
      console.error(error);
    } finally {
      setScreenshotsLoading(false)
    }
  }, [activeOrganization, filters]);

  const getMetaInformation = useCallback(async () => {
    try {
      const {
        data: { members, projects, statuses },
      } = await Api.Get(
        `/organization/${activeOrganization?.id}/screenshot/meta`,
        {
          date: filters.date
        }
      );

      setProjects(
        projects.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
      setMembers(members);

      setStatuses(statuses)
    } catch (error) {
      console.error(error);
    }
  }, [activeOrganization, filters.date]);

  useEffect(() => {
    getMetaInformation();
  }, [getMetaInformation]);

  useEffect(() => {
    getScreenshots();
  }, [getScreenshots]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      sort_by: sortBy,
    }));
  }, [sortBy]);

  return (
    <MainLayout>
      <div className="py-8 md:p-5 font-poppins">
        <div className="flex flex-col lg:flex-row justify-between lg:items-center">
          <div className="flex items-center gap-x-2 mt-4 mb-7">
            <Button className="w-10 h-10 flex justify-center items-center p-2" onClick={() => navigate(-1)}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
              </svg>
            </Button>
            <h1 className="text-2xl font-semibold mr-2">Screenshots Overview</h1>
          </div>
          <div className="flex gap-2 lg:items-center main flex-col">
            <div className="flex gap-2 flex-col md:flex-row ">
            <Select
  placeholder="Project"
  onChange={handleProjectChange}
  size="large"
  mode="multiple"
  className="w-80 h-12 overflow-hidden"
  options={projects}
  notFoundContent = {<Empty
    image={Empty.PRESENTED_IMAGE_SIMPLE}
    description="No Project Found"
  />}
  maxTagCount={1} // Show only one selected value
  maxTagPlaceholder={(omittedValues) => `${omittedValues.length} more`} // Show the remaining count
  filterOption={(input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
  }
/>


              <Select
                placeholder="Status"
                onChange={handleStatusChange}
                size="large"
                notFoundContent = {<Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="No Status Found"
                />}
                mode="multiple"
                className="w-32 h-12 overflow-hidden"
                options={statuses}
                maxTagCount={1} // Show only one selected value
                maxTagPlaceholder={(omittedValues) => `${omittedValues.length} more`} // Show the remaining count
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
              />
              <div className="flex gap-2">
                <Button
                  type="primary"
                  className="bg-primary flex items-center gap-1 p-5 md:px-7 font-poppins rounded-sm"
                  onClick={() =>
                    setSortBy((prev) => {
                      return prev === "asc" ? "desc" : "asc";
                    })
                  }
                >
                  <p>{sortBy === "asc" ? "Oldest" : "Newest"}</p>
                  <img
                    src={ArrowDown}
                    alt="sort-icon"
                    className={`${sortBy === "asc" && "rotate-180"}`}
                  />
                </Button>
                <CustomDatePicker onChange={handleDateChange} />
              </div>
            </div>
          </div>
        </div>
        <Layout className="font-poppins">
          <MemberSelection
            className="w-4/12 xl:w-3/12 rounded-md sidebar"
            members={members}
            onChange={handleMembersChange}
          />
          <Layout>
            <Content className="bg-white p-4 mx-2 rounded-md">
              <LazyLoad loading={screenshotsLoading} loader={<ScreenshotsLoader cards={12} />}>
                <ScreenshotSide
                  screenshots={screenshots.data ?? []}
                  meta={screenshots.meta ?? {}}
                  filters={filters}
                  onRefresh={() => getScreenshots()}
                />
              </LazyLoad>
            </Content>
          </Layout>
        </Layout>
      </div>
    </MainLayout>
  );
}
