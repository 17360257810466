import { Dropdown, Space } from "antd";
import SelectedCompany from "./Layout/SelectedCompany";
import { DownOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FullScreenLoader from "./FullScreenLoader";

export default function OrganizationSelector({ organizations, onChange }) {

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const delayFuntion = (time) =>{
    setLoading(true);
    setTimeout(() => {
      window.location.reload();
      // setLoading(false);
    }, time);
 
  }
  const { activeOrganization } = useSelector((state) => state.auth);

  const handleMenuItemChange = useCallback(
    ({ key }) => {
      const matchOrg = organizations.find((item) => item.id === parseInt(key));
      if (matchOrg) {
        onChange(matchOrg);
      }
      delayFuntion(1000);
    },
    [organizations, onChange]
  );

  useEffect(() => {
    if (organizations.length > 0) {
      const organizationMenuItems = organizations.map((organization) => ({
        key: organization.id,
        disabled: organization.disabled, // Disable the item if organization.disabled is true
        label: (
          <div className={`flex gap-5 items-center justify-between px-3 py-1.5 rounded-lg  ${activeOrganization?.id === organization.id && 'bg-gray-100'} ${organization?.disabled ? 'opacity-50 cursor-not-allowed' : ''} `}>
            <div  className="flex gap-2 items-center">
              <img
                src={organization.logo}
                alt={organization.logo}
                className="w-10 h-10 rounded-full object-cover"
              />
              <p className="truncate max-w-56">{organization.name}</p>
            </div>
            {
              ['admin'].includes(activeOrganization?.role) &&
              activeOrganization.id === organization.id && (
                <Link to="/settings">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z" />
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                  </svg>
                </Link>
              )
            }
          </div>
        ),
      }));
      setItems([...organizationMenuItems, /*{
        key: 'create-new',
        label: <CreateNewLink />
      }*/]);
    }
  }, [organizations, activeOrganization]);

  return (
    <>
    {loading && <FullScreenLoader />}
    <Dropdown
      menu={{
        items,
        onClick: handleMenuItemChange,
      }}
      trigger={["click"]}
      rootClassName="organization-selector"
    >
      <Space>
        <SelectedCompany logo={activeOrganization?.logo} isActive />
        <DownOutlined />
      </Space>
    </Dropdown>
    </>
  );
}


const CreateNewLink = () => {
  return (
    <Link to="/create-organization">
      <div className="flex items-center gap-x-3 text-sm font-poppins text-blue-500 p-3 rounded-lg">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
        </svg>
        Create Organization
      </div>
    </Link>
  )
}
