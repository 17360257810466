import { DownOutlined } from "@ant-design/icons";
import { Dropdown, message } from "antd";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import LogoutIcon from "../../assets/LogoutIcon.png";
import profileIcon from "../../assets/ProfileIcon.png";
import DownloadIcon from "../../assets/download-icon.svg";
import { logout } from "../../features/auth/authActions";
import {
  setActiveOrganization
} from "../../features/auth/authSlice";
import LogoutModal from "../LogoutModal";
import OrganizationSelector from "../OrganizationSelector";
import Profile from "./Profile";

export default function Navbar() {

  const [showLogout, setShowLogout] = useState(false);
  const { windows_build, mac_build } = useSelector((state) => state.global);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = useCallback(async () => {
    try {
      await dispatch(logout())
      navigate('/login');
    } catch (error) {
      message.error("Something went wrong");
    }
  }, [dispatch, navigate]);

  const items = [
    {
      label: (
        <div className="flex gap-2 items-center font-poppins">
          <div className="flex items-center">
            <img src={profileIcon} alt="profile-icon" />
          </div>
          Profile
        </div>
      ),
      key: "profile",
    },
    {
      label: (
        <div className="flex gap-2 items-center font-poppins">
          <div className="flex items-center">
            <img src={LogoutIcon} alt="profile-icon" />
          </div>
          Logout
        </div>
      ),
      key: "logout",
    },
  ];

  const handleMenuClick = useCallback(({ key }) => {
    const actions = {
      'profile': () => navigate('/profile'),
      'update-password': () => navigate('update-password'),
      'logout': () => handleLogout()
    }

    const callback = actions[key];

    callback();

  }, [navigate, handleLogout])

  return (
    <div className="md:w-4/5  mx-3 flex justify-between items-center">
      <div className="items-center gap-4 hidden lg:flex w-full">
        <p className="text-gray-400 my-1 font-semibold lg:ml-4">
          Download Tracker App
        </p>
        <Link
          type="primary"
          className="h-8 font-poppins bg-primary flex items-center gap-2 text-white rounded-md px-3"
          to={windows_build}
          target="blank"
        >
          Windows
          <img src={DownloadIcon} alt="download icon" className="w-3" />
        </Link>
        <Link
          type="primary"
          className="h-8 font-poppins bg-primary flex items-center gap-2 text-white rounded-md px-3"
          to={mac_build}
          target="blank"
        >
          Mac
          <img src={DownloadIcon} alt="download icon" className="w-3" />
        </Link>
      </div>
      <div className="flex items-center  gap-8 md:w-full md:justify-end">
        <div className="flex gap-2">
          <div className="flex items-center justify-center gap-2">
            <Profile isActive={true} pic={user.avatar} />
            <Dropdown
              menu={{ items, onClick: handleMenuClick }}
              trigger={["click"]}
              placement="bottomRight"
              className="flex gap-2 mt-2"
            >
              <button onClick={(e) => e.preventDefault()}>
                <div className="-mt-1">
                  <p className="text-xs leading-4 text-gray-400">Welcome</p>
                  <p className="leading-4 font-semibold line-clamp-1 max-w-[150px]">{user.name}</p>
                </div>
                <DownOutlined className="mt-2 w-4 text-[#737898]" />
              </button>
            </Dropdown>
          </div>
          <div className="flex items-center organization">
            <OrganizationSelector
              organizations={user?.organizations ?? []}
              onChange={(value) => dispatch(setActiveOrganization(value))}
            />
          </div>
        </div>
        <LogoutModal show={showLogout} onClose={() => setShowLogout(false)} />
      </div>
    </div>
  );
}
