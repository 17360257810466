import { UserOutlined } from '@ant-design/icons'
import { Avatar, Badge, Tooltip } from 'antd'
import React from 'react'

export default function TeamMember({ invite }) {
  return (
    <div >
      <div className='flex rounded-md lg:p-1 items-center gap-2'>
        <Badge
          offset={["-10%", "80%"]}
          style={{
            width: "12px",
            height: "12px",
            backgroundColor: invite?.is_online ? "#6CD818" : "#A8A8A8",
          }}
          dot="true"
        >
          <Avatar
            src={invite.avatar}
            size={50}
            shape="circle"
            icon={<UserOutlined />}
            style={{ borderRadius: "30px", border: "3px solid #3376FF" }}
          />
        </Badge>
        <div>
        <Tooltip title={invite.name ?? invite.email} overlayStyle={{ maxWidth: 300 }}>
  <h3 className="font-semibold max-w-[200px] line-clamp-1 cursor-pointer">
    {invite.name?.length > 20
      ? invite.name.substring(0, 20) + "..."
      : invite.name ?? invite.email}
  </h3>
</Tooltip>
        </div>
      </div>

    </div>

  )
}

