import { createAsyncThunk } from '@reduxjs/toolkit'
import Api from '../../utils/Axios'

export const logout = createAsyncThunk(
  'auth/logout',
  async (_, { rejectWithValue }) => {
    try {
      const response = await Api.Post('/logout')
      return response
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getUserDetails = createAsyncThunk(
  'auth/getUserDetails',
  async (_, { rejectWithValue }) => {
    try {
      const activeOrg = JSON.parse(localStorage.getItem("activeOrg"));
      const params = new URLSearchParams();
      if (activeOrg?.id != null) { // Checks for both null and undefined
        params.append("organization_id", activeOrg.id);
      }
      const response = await Api.Get(`/me?${params.toString()}`)
      return response
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)
