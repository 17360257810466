import {ClockCircleOutlined, InfoCircleOutlined} from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Tooltip,
  message as Notify,
  Empty
} from "antd";
import React, {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import Api from "../../utils/Axios";
import {tooltip} from '../../utils/tooptiips';

const Roles = [
  {
    value: "admin",
    label: "Admin",
  },
  {
    value: "department_head",
    label: "Department Head",
  },
  {
    value: "user",
    label: "User",
  }
];

export default function InviteModal({
                                      open,
                                      onClose,
                                      invite = undefined,
                                    }) {
  const {activeOrganization} = useSelector((state) => state.auth);
  const [inviteForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [departments, setDepartments] = useState([]);
  // const [projects, setProjects] = useState([]);
  // const [selectAll, setSelectAll] = useState(inviteForm?.getFieldValue('projects')?.length === projects?.length);

  const handleModalClose = useCallback((refresh = false) => {
    inviteForm.resetFields()
    // setSelectAll(false)
    onClose(refresh)
  }, [onClose, inviteForm])

  // const handleSelectAll = useCallback(() => {
  //   setSelectAll(true);
  //   inviteForm.setFieldValue('projects', projects.map(project => project.value))
  // }, [inviteForm, projects])

  // const handleDeselectAll = useCallback(() => {
  //   setSelectAll(false);
  //   inviteForm.setFieldValue('projects', [])
  // }, [inviteForm])

  const handleFormSubmission = useCallback(
      async (values) => {
        try {
          setLoading(true);
          let endpoint;
          let requestData = values;
          if (invite) {
            endpoint = `/organization/${activeOrganization?.id}/team/invitation/${invite.id}`;
            requestData = {...values, user_email: values?.user_email || invite.email};
          } else {
            endpoint = `/organization/${activeOrganization?.id}/team/invitation`;
          }

          const {message} = await Api.Post(endpoint, requestData);

          Notify.success(message);
          inviteForm.resetFields();
          handleModalClose(true)
        } catch (error) {
          if (error.response && error.response.status === 422) {
            const errors = error.response.data.errors;
            const validationErrors = Object.keys(errors).map((key) => ({
              name: key,
              errors: errors[key],
            }));
            inviteForm.setFields(validationErrors);

            let fError;
            if ((fError = Object.keys(errors).filter(key => {
              return Object.keys(inviteForm.getFieldsValue()).indexOf(key) < 0
            })).length > 0) {
              Notify.error(errors[fError[0]][0]);
            }

          } else {
            console.error(error);
            Notify.error("Something went wrong");
          }
        } finally {
          setLoading(false);
        }
      },
      [invite, activeOrganization, inviteForm, handleModalClose]
  );

  const init = useCallback(async () => {
    try {
      const [departmentsResponse] = await Promise.all([
        Api.Get(`/organization/${activeOrganization?.id}/departments`),
        // Api.Get(`/organization/${activeOrganization?.id}/projects`),
      ]);

      let {data: departmentsData} = departmentsResponse;
      // let { data: projectsData } = projectsResponse;

      setDepartments(
          departmentsData.map((item) => ({
            value: item.id,
            label: item.name,
          }))
      );

      // setProjects(
      //   projectsData.map((item) => ({
      //     value: item.id,
      //     label: item.name,
      //   }))
      // );
    } catch (error) {
      console.error(error);
      Notify.error("Something went wrong");
    }
  }, [activeOrganization]);

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    if (invite) {
      const ref = {...invite, user_email: invite.email, departments: invite?.department?.map(item => item.id) ?? null};
      // setSelectAll(projects?.length === invite?.projects?.length);
      inviteForm.setFieldsValue(ref);
    }
  }, [invite, inviteForm]);

  return (
      <Modal
          open={open}
          onCancel={() => handleModalClose(false)}
          className="w-11/12 lg:w-1/2"
          footer={false}
          centered
          destroyOnClose
      >
        <div className="m-2 items-center text-center font-poppins">
          <h3 className="font-semibold font-poppins text-left text-lg">
            {invite ? "Edit Invitation" : "Invite New Member Via Email"}
          </h3>
          <div>
            <Form
                onFinish={handleFormSubmission}
                autoComplete="off"
                size="large"
                className="my-5 !mb-0 font-poppins invite"
                disabled={loading}
                form={inviteForm}
            >
              <div className="grid  grid-cols-1 md:grid-cols-2 gap-3 ">

                <div>
                  <p className="text-left font-semibold font-poppins my-2">
                    Email <span className="text-red-500">*</span>
                  </p>
                  <Form.Item
                      name="user_email"
                      className="text-left"
                      rules={[
                        {
                          required: true,
                          type: "email",
                          message: <span style={{ fontSize: '12px',display: 'block' }}>Please enter a valid email address</span>,
                        },
                      ]}
                  >
                    <Input placeholder="Email" className="rounded-none"/>
                  </Form.Item>
                </div>

                <div>
                  <div className="flex justify-between">
                    <p className="text-left font-semibold font-poppins my-2">
                      Hours per day <span className="text-red-500">*</span>
                    </p>
                    <Tooltip
                        placement="top"
                        title={tooltip.hoursPerDay}
                    >
                      <InfoCircleOutlined/>
                    </Tooltip>
                  </div>

                  <div className="flex h-10">
                    <Form.Item
                      name="daily_hours"
                      className="w-full text-left"
                      rules={[
                        { required: true,
                          message: <span style={{ fontSize: '12px',display: 'block' }}>Please enter the Hours per day</span>
                        },
                        {
                          // validator: (_, value) => {
                          //   if(!value) {
                          //     return Promise.resolve();
                          //   }
                          //   if (value < 1 || value > 24) {
                          //     return Promise.reject(
                          //       <span style={{ fontSize: "11px", display: "block" }}>
                          //         Please enter the Hours between 1-24
                          //       </span>
                          //     );
                          //   } else if (value > 0 && !/^(0[1-9]|1[0-9]|2[0-4])$/.test(value)) {
                          //     return Promise.reject(
                          //       <span style={{ fontSize: "11px", display: "block" }}>
                          //         Please enter the Hours between 1-24
                          //       </span>
                          //     );
                          //   }
                          //   return Promise.resolve();
                          // },
                          validator: (_, value) => {
                            if (!value) {
                              return Promise.resolve();
                            }
                            if (value < 1 || value > 24) {
                              return Promise.reject(
                                <span style={{ fontSize: "11px", display: "block" }}>
                                  Please enter the hours between 1-24
                                </span>
                              );
                            }
                            return Promise.resolve();
                          },
                          
                        },
                      ]}
                    >
                      <Input
                          type="number"
                          placeholder="8"
                          prefix={
                            <ClockCircleOutlined className="text-[#5E6366] text-lg mt-[2px]"/>
                          }
                          className="w-full rounded-none"
                          maxLength={2}
                          onKeyPress={(e) => {
                            if (!/^[0-9]$/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          onPaste={(e) => {
                            const pasteData = e.clipboardData.getData("text");
                            if (!/^\d+$/.test(pasteData) || parseInt(pasteData, 10) < 1 || parseInt(pasteData, 10) > 24) {
                              e.preventDefault();
                            }
                          }}
                      />
                    </Form.Item>
                    <div className="text-white p-2 bg-primary rounded-sm px-4">
                      <p>Hours</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                <div>
                  <div className="flex justify-between items-center">
                    <p className="text-left font-semibold font-poppins my-2">
                      Role <span className="text-red-500">*</span>
                    </p>
                    {/*<a className="text-primary" href="https://workotick.com/#faq-sec" rel="noopener noreferrer" target="_blank">Learn More</a>*/}
                  </div>
                  <div className="invite">
                    <Form.Item
                        name="role"
                        className="text-left"
                        rules={[
                          {
                            required: true,
                            message: <span style={{ fontSize: '12px',display: 'block' }}>Please select a role for the member</span>,
                          },
                        ]}
                    >
                      <Select
                          placeholder="Role"
                          className="text-left"
                          options={Roles}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div>
                  <p className="text-left font-semibold font-poppins my-2">
                    Department 
                    {/* <span className="text-red-500">*</span> */}
                  </p>
                  <Form.Item name="departments" className="text-left" 
                  // rules={[
                  //       {
                  //         required: true,
                  //         message: <span style={{ fontSize: '12px',display: 'block' }}>Please select a department for the member</span>,
                  //       },
                  //     ]}
                      >
                    <Select
                        mode="multiple"
                        placeholder="Assign Department"
                        className="text-left"
                        options={departments}
                        maxTagCount={3}
                        notFoundContent = {<Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description="No Department Found"
                        />}
                    />
                  </Form.Item>
                </div>
              </div>
              {/* <div>
              <div className="flex justify-between">
                <p className="text-left font-semibold font-poppins my-2">
                  Projects
                </p>
                {
                  selectAll ? (
                    <Button type="button" className="text-primary p-0" onClick={handleDeselectAll}>Deselect All</Button>
                  ) : (
                    <Button type="button" className="text-primary p-0" onClick={handleSelectAll}>Select All</Button>
                  )
                }
              </div>
              <Form.Item name="projects" className="multi">
                <Select
                  mode="multiple"
                  tagRender={<ProjectTags />}
                  style={{ width: "100%" }}
                  options={projects}
                />
              </Form.Item>
            </div> */}
              <Form.Item name="submit" className="mt-4 mb-0">
                <Button
                    type="primary"
                    htmlType="submit"
                    className="bg-primary w-full h-10 rounded-sm"
                    loading={loading}
                >
                  {invite ? "Update" : "Send Invite"}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
  );
}
