import momentTz from 'moment-timezone';
import moment from 'moment';
import dayjs from 'dayjs';
import Api from './Axios';
import { setActiveOrganization } from '../features/auth/authSlice';

export let outsideStore;

export function getFormattedTimestamp(timestamp, timezone = 'Asia/Karachi', format = 'YYYY-MM-DD') {
  if (!moment(timestamp).isValid()) {
    return '--';
  }
  return momentTz(timestamp).tz(timezone).format(format);
}

export function secondsToHHMM(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
}

export async function updateOrganization(organizationId) {
  try {
    const { data } = await Api.Get(`organization/${organizationId}`);
    const userData = JSON.parse(localStorage.getItem('user'));
    const organizations = userData.organizations;
    const newOrganization = organizations.map(organization => {
      if (organization.id === data.id) {
        return data;
      }
      return organization;
    })
    outsideStore.dispatch(setActiveOrganization(data))
    localStorage.setItem('user', JSON.stringify({ ...userData, organizations: newOrganization }))
  } catch (error) {
    throw (error);
  }
}

export function injectStore(_store) {
  outsideStore = _store
}
