import { useEffect, useState } from 'react';
import { Select, Empty} from "antd";
import CustomDatePicker from '../../../components/CustomDatePicker';
import dayjs from 'dayjs';

export default function Filters({ data: { users, projects, departments }, onChange }) {

  const [filters, setFilters] = useState({
    department: undefined,
    projects: undefined,
    users: undefined,
    dates: [dayjs().startOf('week').format('YYYY-MM-DD'), dayjs().endOf('week').format('YYYY-MM-DD')]
  });

  useEffect(() => {
    onChange(filters)
    // eslint-disable-next-line
  }, [filters])


  return (
    <div className='flex gap-2 xl:items-center main flex-col flex-1'>
      <div className='flex gap-2 flex-col md:flex-row flex-wrap justify-end w-full'>
        <Select
          placeholder='Department'
          className='flex-1'
          mode='multiple'
          options={departments}
          onChange={(value) => setFilters(prev => ({
            ...prev,
            departments: value
          }))}
          notFoundContent = {<Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No Department Found"
          />}
          maxTagCount={1}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
        />
        <Select
          placeholder='Select Team Member'
          mode='multiple'
          className='flex-1'
          onChange={(value) => setFilters(prev => ({
            ...prev,
            users: value
          }))}
          notFoundContent = {<Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No Team Member Found"
          />}
          options={users}
          maxTagCount={1}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
        />

        <Select
          placeholder='Project'
          className='flex-1'
          mode='multiple'
          options={projects}
          onChange={(value) => setFilters(prev => ({
            ...prev,
            projects: value
          }))}
          notFoundContent = {<Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No Project Found"
          />}
          maxTagCount={1}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
        />

        <div className='flex gap-2'>
          <CustomDatePicker.WeekPicker
            onChange={(value) => setFilters(prev => ({
              ...prev,
              dates: [
                dayjs(value).startOf('week').format('YYYY-MM-DD'),
                dayjs(value).endOf('week').format('YYYY-MM-DD'),
              ]
            }))}
          />
        </div>
      </div>
    </div>
  )
}
